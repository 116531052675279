import { Col, List, Row } from 'antd';
import Title from 'antd/lib/typography/Title';
import React, { Fragment } from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { Country, Customer, Participant, Product, ProductDetail } from '../../utils/types';
import ParticipantItem from './participantItem';

interface IProps {
    participantToEdit?: Participant;
    participantToEditId?: number;
    countries: Country[] | undefined;
    participants: Participant[];
    productSelected?: Product;
    customer?: Customer;
    detailSelected?: ProductDetail;
    goToTab: (tabId: string) => void;
    addParticipant: (participant: Participant) => void;
    editParticipants: (id: number, participant: Participant) => void;
    cancelEditParticipant: () => void;
    saveEditParticipant: (id: number, participant: Participant) => void;
    deleteParticipant: (id: number) => void;
};

interface State {
    participants: Participant[];
    participantsTabDisabled: boolean;
    productsTabDisabled: boolean;
    createParticipant?: Participant;
    addingParticipant: boolean;
}

type Props = IProps & WrappedComponentProps;

class ParticipantsTab extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            participants: [],
            participantsTabDisabled: false,
            productsTabDisabled: false,
            createParticipant: {},
            addingParticipant: false,
        };
    }
    componentDidMount() {
        this.props.participantToEdit && this.setState({ createParticipant: this.props.participantToEdit });
    }

    componentDidUpdate(prevProps: Props) {
        if (prevProps.participantToEditId !== this.props.participantToEditId) {
            this.setState({ createParticipant: { ...this.props.participantToEdit } }, () => console.log(">>>", this.state.createParticipant));
        }
    }

    addParticipant = (participant: Participant) => {
        this.props.addParticipant(participant);
        this.setState({ createParticipant: {}, addingParticipant: false });
    };

    saveEditParticipant = (id: number, participant: Participant) => {
        this.props.saveEditParticipant(id, participant);
        this.setState({ createParticipant: {}, addingParticipant: false });
    };

    cancelParticipant = () => {
        this.setState({ createParticipant: {}, addingParticipant: false });
        this.props.cancelEditParticipant();
    };

    showAddParticipant = () => {
        return !(this.props.productSelected !== undefined && this.props.productSelected.type !== "subscription" && this.props.participants.length >= 1 && this.props.participantToEditId === undefined);
    }

    renderParticipant = (participant: Participant, id: number): React.ReactNode => {
        return <ParticipantItem detailSelected={this.props.detailSelected} productSelected={this.props.productSelected} customer={this.props.customer} saveEditParticipant={this.saveEditParticipant} participantToEditId={this.props.participantToEditId} key={`participant-${this.props.participantToEditId}-${id}`} id={id} editParticipants={this.props.editParticipants} deleteParticipant={this.props.deleteParticipant} countries={this.props.countries} cancelParticipant={this.cancelParticipant} addParticipant={this.addParticipant} participant={participant} participantsTabDisabled={this.state.participantsTabDisabled} productsTabDisabled={this.state.productsTabDisabled} goToTab={this.props.goToTab} />;
    }

    render() {
        const { formatMessage } = this.props.intl;
        const { createParticipant } = this.state;

        return (
            <Fragment>
                {this.showAddParticipant() && <Title level={2}>{formatMessage({ id: 'participant.add' })}</Title>}
                {
                    this.showAddParticipant() && createParticipant &&
                    <List
                        className="__add-participant-form"
                        dataSource={[createParticipant]}
                        renderItem={(p) => {
                            return this.renderParticipant(p, this.props.participantToEditId ? this.props.participantToEditId : -1)
                        }} />
                }
                <Title level={2}>{formatMessage({ id: 'participant.title' })}</Title>
                <Row style={{ marginTop: '6px' }} gutter={[12, 12]}>
                    {
                        this.props.participants.length > 0 ?
                            this.props.participants.map((participant, id) => {
                                return this.renderParticipant(participant, id);
                            })
                            :
                            <Col span={24}>{formatMessage({ id: "participant.none" })}</Col>
                    }
                </Row>
            </Fragment>
        )
    }
}

export default injectIntl(ParticipantsTab);