import { Route, Routes } from "react-router-dom";
import Registration from '../registration/registration';
import Paysuccess from '../registration/paysuccess';
import PayError from '../registration/paysuccess';
import Layout, { AuthBackOfficeLayout, BackOfficeLayout, FrontLayout, LocaleLayout, NotFoundLayout, UnAuthBackOfficeLayout } from "../specialRoutes/layouts";
import Login from "../backoffice/login/login";
import BackofficeAdministration from "../backoffice/administration/administration";

const Root = () => {



    return (
    <Routes>
        <Route path="/" element={<Layout />}>
            <Route path=':lang' element={<LocaleLayout />}>
                <Route element={<FrontLayout />}>

                    <Route path="registration" element={<Registration />} />
                    <Route path="pay_success" element={<Paysuccess />} />
                    <Route path="pay_error" element={<PayError />} />
                </Route>
                <Route path="backoffice" element={<BackOfficeLayout />}>
                    <Route element={<UnAuthBackOfficeLayout />}>
                        <Route path="login" element={<Login />} />
                    </Route>
                    <Route element={<AuthBackOfficeLayout />}>
                        <Route path="administration" element={<BackofficeAdministration />} />
                    </Route>
                </Route>
                <Route path="*" element={<NotFoundLayout />} />
            </Route>
        </Route>
    </Routes>
    );
};

export default Root;