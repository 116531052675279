import axiosapi from './api';
import authaxiosapi, { login } from './auth_api';
import { BackofficeAddCustomer, BackofficeLoginBodyRequest, PayyoCaptureBobyRequest, RegistrationBobyRequest } from './types';

// const headers = {
//     'Content-Type': 'application/json',
//     'Accept': 'application/json',
//     'Authorization': 'Basic YXBpXzdkOTE4OTg5Y2MzYmM0MjcyOWZjNzc2NGIxNDU6NmU2N2NhY2FhNWNlODQ3Nzg1NTJkNTZjMmFkMWMxNWRjMGI5OWJlZGZmYmU2OTkwNGMwNDYzMmFiYzk4ZDYyNg=='
// }

export default class Network {
    static getCountriesList = (lang: string): Promise<any> => axiosapi.get(`statics/countries?lang=${lang}`);
    static getCantonList = (lang: string): Promise<any> => axiosapi.get(`statics/cantons?lang=${lang}`);

    // BACKOFFICE
    static login = (body: BackofficeLoginBodyRequest): Promise<any> => login(body);
    static getCustomersList = (): Promise<any> => authaxiosapi.get('we-administration/customers');
    static getCompanyInfo = (): Promise<any> => authaxiosapi.get('we-administration/company');
    static getFieldsValues = (customer_id: number): Promise<any> => authaxiosapi.get('we-administration/fields-values', { params: { "customerId": customer_id } });
    static getFields = (): Promise<any> => authaxiosapi.get('we-administration/get-fields');
    static getCountryName = (country_id: string): Promise<any> => authaxiosapi.get('statics/countries', { params: { "countryId": country_id } });
    static getCantonName = (country_id: string): Promise<any> => authaxiosapi.get('statics/cantons', { params: { "cantonId": country_id } });
    static changePaymentState = (customerId: number, paymentState: boolean): Promise<any> => authaxiosapi.post('we-administration/payment-state', { customerId: customerId, paymentState: paymentState });

    static generateLink = (customerId: number): Promise<any> => authaxiosapi.post('we-administration/generate-token', { id: customerId });
    static saveCustomer = (customer: BackofficeAddCustomer): Promise<any> => authaxiosapi.post('we-administration/save-customer', { customer });
    static downloadMonthReport = (month: number, year: number): Promise<any> => authaxiosapi.get('we-administration/month-report', { params: { "month": month, "year": year } });
    static downloadYearReportSCCM = (year: number): Promise<any> => authaxiosapi.get('we-administration/year-report-sccm', { params: { "year": year } });
    static downloadYearReport = (year: number): Promise<any> => authaxiosapi.get('we-administration/year-report', { params: { "year": year } });
    static downloadCertificate = (lang: string, customerId: number): Promise<any> => authaxiosapi.get(`payment/download-terraxis-certificate`, { responseType: 'blob', params: { "lang": lang, "customerId": customerId } });
    static deleteCustomer = (customerId: number): Promise<any> => authaxiosapi.delete(`we-administration/customers/`, { params: { "customerId": customerId } });

    static backofficeSendIbanToCustomer = (query: string, lang: string, customerId: number, reminder: string): Promise<any> => authaxiosapi.get(`payment/payyo`, { params: { "query": query, "lang": lang, "customerId": customerId, "reminder": reminder } });
    static sendPaymentConfirmation = (query: string, lang: string, customerId: number): Promise<any> => authaxiosapi.get(`payment/payyo`, { params: { "query": query, "lang": lang, "customerId": customerId } });


    // FRONTOFFICE


    static register = (info: RegistrationBobyRequest): Promise<any> => axiosapi.post('payment/payyo', info);
    static payyoCapture = (info: PayyoCaptureBobyRequest): Promise<any> => axiosapi.post('payment/payyo', info);

    // static createCustomer = (info: CustomerBodyRequest): Promise<any> => axiosapi.post('payment/payyo', info);
    static getMail = (query:string, lang: string, customerId: number): Promise<any> => axiosapi.get(`payment/payyo`, { params: { "query": query, "lang": lang, "customerId" : customerId }});
    static sendIbanToCustomer = (query: string, lang: string, customerId: number): Promise<any> => axiosapi.get(`payment/payyo`, { params: { "query": query,  "lang": lang, "customerId" : customerId }});
}