import React from 'react';
import ReactDOM from 'react-dom';
import { Alert, message } from 'antd';

/**
 * Create an alert
 * @param message the message to display
 * @param type the type of the message: "success" | "info" | "warning" | "error" | undefined
 * @param showIcon if the icon must be visible
 * @param description an additional text
 */
export const alert = (message: string, type: "success" | "info" | "warning" | "error" | undefined, showIcon: boolean = true, description?: string) => {
    //get root by id
    const root = document.getElementById("root");

    //delete previous alert
    const previousAlert = document.getElementById('alert-container');
    if (previousAlert) root?.removeChild(previousAlert);

    //add div
    let div = document.createElement("div");
    div.className = "container-alert";
    div.id = "alert-container";
    root?.appendChild(div);

    //create alert
    const component = <Alert
        message={message}
        type={type}
        showIcon={showIcon}
        description={description} />

    // render component
    const alert = document.getElementById('alert-container');
    if (alert) ReactDOM.render(component, alert);
}

/**
 * Display an error message
 * @param msg the message to display
 * @param shakeId the id of the component to shake
 */
export const displayErrorMessage = (msg: string, shakeId?: string): void => {
    const component = shakeId ? document.getElementById(shakeId) : undefined;
    if (component && !component.className.includes("shake-animation")) {
        component.className += " shake-animation";
        setTimeout(() => component.className = component.className.replace(" shake-animation", ""), 1100);
    }
    message.error(msg, 6);
}