import axios from 'axios';


let BASE_URL = process.env.REACT_APP_BACK_BASE_URL;
if (!BASE_URL) {
    BASE_URL = 'https://backend.webevolutions.ch/';
}


const axiosapi = axios.create({
    baseURL: BASE_URL,
    timeout: 40000,

});

export default axiosapi;