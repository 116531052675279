import axios from 'axios';
import { BackofficeLoginBodyRequest } from './types';

let BASE_URL = process.env.REACT_APP_BACK_BASE_URL;
if (!BASE_URL) {
    BASE_URL = 'https://backend.webevolutions.ch/';
}

const authaxiosapi = axios.create({
    baseURL: BASE_URL,
    timeout: 40000,
    
});

export const login = (body: BackofficeLoginBodyRequest) => {
    return new Promise<void>((resolve, reject) => {
        authaxiosapi.post('we-administration/login', body)
            .then((response: any) => {
                localStorage.setItem("token", response.token);
                authaxiosapi.defaults.headers.common["Authorization"] = response.token;
                resolve();
            })
            .catch(reject);
      });
}



authaxiosapi.defaults.headers.common["Authorization"] = localStorage.getItem("token") ?? "";

authaxiosapi.interceptors.response.use(response => {
    return response.data;
});




export default authaxiosapi;