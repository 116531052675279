import { notification } from "antd";
import {
    useLocation,
    useNavigate,
    useParams
} from "react-router-dom";

export const withRouter = (Component: any) => {
    const ComponentWithRouterProp = (props: any) => {
        let location = useLocation();
        let navigate = useNavigate();
        let params = useParams();
        const [api, contextHolder] = notification.useNotification();

        return (
            <>
                {contextHolder}
                <Component
                    {...props}
                    notification={api}
                    router={{ location, navigate, params }}
                />
            </>
        );
    }

    return ComponentWithRouterProp;
}
export const Navigate = (destination: any) => {
    const NavigateSilently = () => {
        let navigate = useNavigate();
        return navigate(destination);
    }

    return NavigateSilently;
}
export const Location = () => {
    const LocationSilently = () => {
        let location = useLocation();
        return location
    }

    return LocationSilently;
}
export const Params = () => {
    const ParamsSilently = () => {
        let params = useParams();
        return params
    }

    return ParamsSilently;
}