import React, { ReactNode } from 'react'
import { Layout, Col, Row } from 'antd';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faLongArrowAltUp, faPhone } from '@fortawesome/free-solid-svg-icons'
import { faFacebookF } from '@fortawesome/free-brands-svg-icons'

import LogoImage from '../../img/ski-club-crans-montana.png';

import Sponsor1 from '../../img/logo-sponsor-gerber-pizzeria.png';
import Sponsor2 from '../../img/logo-sponsor-hostellerie-pas-de-l-ours.png';
import Sponsor3 from '../../img/logo-sponsor-bar-amadeus.png';
import Sponsor4 from '../../img/logo-sponsor-hotel-de-l-etrier.png';
import Sponsor5 from '../../img/logo-sponsor-swiss-learning.png';

import BackToTop from '../../img/back-to-top.png';

interface IProps {
    children: ReactNode
 }

type Props = IProps & WrappedComponentProps;

interface State { }

class Container extends React.Component<Props, State> {

    
    

    render() {
        const { Header, Content, Footer } = Layout;

        let CMS_BASE_URL = process.env.REACT_APP_CMS_BASE_URL;
        if (!CMS_BASE_URL) {
            CMS_BASE_URL = 'http://skiclubcransmontana.ch';
        }

        return (
            <Layout style={{ minHeight: "100vh" }} className="layout">
                <Header>
                    <Row>
                        <Col span={8}>
                        </Col>
                        <Col span={8} style={{ textAlign: 'center' }}>
                            <a href="https://shop.skiclubcransmontana.ch/">
                                <img className="__ski-club-logo-img" alt="Ski Club" src={LogoImage} />
                            </a>
                        </Col>
                        <Col span={8}>
                        </Col>
                    </Row>
                </Header>
                <Content>
                    {this.props.children}
                </Content>
                <Footer >
                    <Row>
                        <Col span={24} style={{ textAlign: "center", marginTop: '20px' }}>
                            <a href="tel:441794787294" className="__link-round-icon" rel="noopener noreferrer"><FontAwesomeIcon icon={faPhone} /></a>
                            <a href="https://www.facebook.com/groups/132620420237091/" className="__link-round-icon" rel="noopener noreferrer" target="_blank"><FontAwesomeIcon icon={faFacebookF} /></a>
                            <a href="mailto:ale.snow@bluewin.ch" className="__link-round-icon" rel="noopener noreferrer" target="_blank"><FontAwesomeIcon icon={faEnvelope} /></a>
                        </Col>
                        <Col span={24} style={{ textAlign: "center", marginTop: '20px' }}>
                            <a href="https://brasseriegerber.ch/" rel="noopener noreferrer" target="_blank"><img className="__sponsor-logo-img" alt="Ski Club" src={Sponsor1} /></a>
                            <a href="https://pasdelours.ch/" rel="noopener noreferrer" target="_blank"><img className="__sponsor-logo-img" alt="Ski Club" src={Sponsor2} /></a>
                            <a href="https://baramadeus.ch/" rel="noopener noreferrer" target="_blank"><img className="__sponsor-logo-img" alt="Ski Club" src={Sponsor3} /></a>
                            <a href="https://www.hoteletrier.ch/" rel="noopener noreferrer" target="_blank"><img className="__sponsor-logo-img" alt="Ski Club" src={Sponsor4} /></a>
                            <a href="https://www.swisslearning.com/" rel="noopener noreferrer" target="_blank"><img className="__sponsor-logo-img" alt="Ski Club" src={Sponsor5} /></a>
                            <a href="https://www.alptex.ch/" rel="noopener noreferrer" target="_blank"><img className="__sponsor-logo-img" alt="Ski Club" src={`${CMS_BASE_URL}/templates/yootheme/cache/logo-alptex-5b699468.png`} /></a>
                        </Col>
                        <Col span={24} style={{ textAlign: "center", marginTop: '20px' }}>
                            <span><img className="__sponsor-logo-img" alt="Ski Club" src={`${CMS_BASE_URL}/templates/yootheme/cache/logo-avalanche-0bcc0ae6.png`} /></span>
                            <a href="https://www.bonvinnettoyages.ch/" rel="noopener noreferrer" target="_blank"><img className="__sponsor-logo-img" alt="Ski Club" src={`${CMS_BASE_URL}/templates/yootheme/cache/logo-bonvin-nettoyages-462dc4fd.png`} /></a>
                            <a href="http://www.cabanedesviolettes.ch/" rel="noopener noreferrer" target="_blank"><img className="__sponsor-logo-img" alt="Ski Club" src={`${CMS_BASE_URL}/templates/yootheme/cache/logo-la-cabane-des-violettes-1316947d.png`} /></a>
                            <a href="https://alaia.ch/" rel="noopener noreferrer" target="_blank"><img className="__sponsor-logo-img" alt="Ski Club" src={`${CMS_BASE_URL}/templates/yootheme/cache/logo-alaia-8ab548ee.png`} /></a>
                            <a href="https://boulangerietaillens.ch/" rel="noopener noreferrer" target="_blank"><img className="__sponsor-logo-img" alt="Ski Club" src={`${CMS_BASE_URL}/templates/yootheme/cache/logo-tailllens-4825fbff.png`} /></a>
                            <a href="https://webevolutions.ch/fr/" rel="noopener noreferrer" target="_blank"><img className="__sponsor-logo-img" alt="Ski Club" src={`${CMS_BASE_URL}/templates/yootheme/cache/logo-webevolutions-2ce5a379.png`} /></a>
                        </Col>
                        <Col span={24} style={{ textAlign: "center", marginTop: '20px' }}>
                            <a href="https://www.zermattensports.ch/fr/" rel="noopener noreferrer" target="_blank"><img className="__sponsor-logo-img" alt="Ski Club" src={`${CMS_BASE_URL}/templates/yootheme/cache/intersport-zermatt-26b9f730.png`} /></a>
                            <a href="https://www.raiffeisen.ch/" rel="noopener noreferrer" target="_blank"><img className="__sponsor-logo-img" alt="Ski Club" src={`${CMS_BASE_URL}/templates/yootheme/cache/raiffeisen-68cae516.png`} /></a>
                            <a href="https://www.olisbreak.com/" rel="noopener noreferrer" target="_blank"><img className="__sponsor-logo-img" alt="Ski Club" src={`${CMS_BASE_URL}/templates/yootheme/cache/oli-0e0eff66.png`} /></a>
                        </Col>
                        <Col span={24} style={{ textAlign: "center", marginTop: '20px' }}>© Ski Club Crans-Montana</Col>
                        <Col span={24} style={{ textAlign: "center", marginTop: '20px' }}>Shop propulsé par <a className="__footer-copyright-link" href="https://www.webevolutions.ch" rel="noopener noreferrer" target="_blank">WebEvolutions</a></Col>

                        <Col span={24} style={{ textAlign: "center", marginTop: '20px' }}>
                            <img className="__back-to-top-img" alt="Ski Club" src={BackToTop} />
                            <FontAwesomeIcon size="lg" className="__back-to-top-arrow" onClick={() => window.scroll({top: 0, left: 0, behavior: 'smooth' })} icon={faLongArrowAltUp} />
                        </Col>
                    </Row>
                </Footer>
            </Layout>
        )
    }
}



export default injectIntl(Container);