import { useCallback, useEffect, useState } from "react";
import { IntlProvider } from "react-intl";
import { useParams } from "react-router-dom";
import en from '../../locale/en.json';
import fr from '../../locale/fr.json';
import React from "react";

interface Props {
    children?: React.ReactNode;
}

const LanguageProvider = (props: Props) => {

    const [language, setLanguage] = useState<{ local: string, messages: any; }>({ local: 'fr', messages: fr });
    const { lang } = useParams();

    const getLanguage = useCallback(() => {
        switch (lang) {
            case 'fr':
                return { local: 'fr', messages: fr };
            case 'en':
            default:
                return { local: 'en', messages: en };
        }
    }, [lang]);

    useEffect(() => {
        setLanguage(getLanguage);
    }, [getLanguage]);

    return (
        <IntlProvider locale={language.local} messages={language.messages}>
            {props.children}
        </IntlProvider>
    );
};

export default LanguageProvider;