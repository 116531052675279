import React, { ReactNode } from 'react'
import { Layout, Button, Menu, Dropdown } from 'antd';
import Logo_WE from '../../../img/we_logo_transparent.png';
import { UserOutlined } from '@ant-design/icons';
import { injectIntl, WrappedComponentProps } from 'react-intl';


interface Props extends WrappedComponentProps{
    children: ReactNode;
}


interface State { }

/**
 * Component that represent a page container
 */
class Container extends React.Component<Props, State> {

    /**
     * Logout current user
     */
    logout = () => {
        localStorage.removeItem("token");
        window.location.reload();
    }

    render() {
        const { Header, Content, Footer } = Layout;
        return (
            <Layout style={{ minHeight: '100%' }}>
                <Header className="flex-space-between">
                    <img id="__header-logo" alt="Header logo" src={Logo_WE} className="container-header-logo" />
                    <div>
                        <Dropdown trigger={['click']} overlay={
                            <Menu>
                                <Menu.Item key="logout" onClick={this.logout}>{this.props.intl.formatMessage({ id: 'logout' })}</Menu.Item>
                            </Menu>
                        }>
                            <Button icon={<UserOutlined />} size="large" shape="circle" type="primary" />
                        </Dropdown>
                    </div>
                </Header>
                <Content className="container-content">
                    {this.props.children}
                </Content>
                <Footer>
                    <div className="container-footer-divider">
                        <div className="container-footer-line" />
                        <p className="container-footer-dot">●</p>
                    </div>
                    <div className="container-footer-image">
                        <img alt="Footer logo" src={Logo_WE} className="container-footer-logo" />
                    </div>
                    <div className="container-footer-content">
                        {"© 2021 WEBEVOLUTIONS SARL"}
                    </div>
                </Footer>
            </Layout>
        )
    }
}

export default injectIntl(Container);