import React from 'react';
import { Row, Col, Button } from 'antd';
import { Typography } from 'antd';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { PayyoCaptureBobyRequest, RouterProps } from '../../utils/types';
import Network from '../../utils/network';
import { LoadingOutlined } from '@ant-design/icons';
import { withRouter } from '../../utils/withRouter';

interface IProps {
};

interface State {
    errorId: number,
    transactionId?: string,
    infoValuesStorage?: PayyoCaptureBobyRequest;
    nowDateTime: Date,
}

const PAYMENTDONE = "settled"
const PAYMENTYETDONE = "yet settled"
const PAYMENTERROR = "notSettled"

type Props = IProps & WrappedComponentProps & RouterProps;

class Paysuccess extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            errorId: -1,
            infoValuesStorage: undefined,
            transactionId: undefined,
            nowDateTime: new Date()
        }

    }
    componentWillUnmount() {
        localStorage.clear();
    }

    componentDidMount() {
        document.title = "Ski Club Crans-Montana: Confirmation de paiement";
        const infoValuesStorage: PayyoCaptureBobyRequest = JSON.parse(localStorage.getItem('payyoValues') || '{}');

        if (Object.keys(infoValuesStorage).length === 0 && infoValuesStorage.constructor === Object) {
            window.location.href = "/";
        } else {
            const { formatMessage } = this.props.intl;
            const search = this.props.router.location.search;
            const params = new URLSearchParams(search);
            const transactionId = params.get('transaction_id') || undefined;
            this.setState({ transactionId, infoValuesStorage })
            const company_id = infoValuesStorage.companyId ? parseInt(infoValuesStorage.companyId) : 0;

            if (company_id === 0 || transactionId === null || transactionId === "" || transactionId === undefined) {
                window.location.href = "/fr/registration";
            } else {
                infoValuesStorage.transactionId = transactionId as string;
                if (!infoValuesStorage.fieldsValues) {
                    this.setState({ errorId: 3 });
                } else {
                    infoValuesStorage.fieldsValues.transaction_id = transactionId as string;
                    let infoValues: PayyoCaptureBobyRequest = {
                        ...infoValuesStorage
                    }

                    Network.payyoCapture(infoValues).then(data => {
                        if (!data.data["result"]) {
                            this.setState({ errorId: 1 });
                        } else if (data.data["result"] === PAYMENTDONE) {
                            this.setState({ errorId: 0 })
                            console.log("PAYED MAIL YES")
                            Network.getMail("sendMailSkiClubCM", formatMessage({ id: 'lang' }), data.data["customerId"]).then(data => console.log(data));
                        } else if (data.data["result"] === PAYMENTYETDONE) {
                            this.setState({ errorId: 0 })
                        } else if (data.data["result"] === PAYMENTERROR) {
                            this.setState({ errorId: 2 });
                        } else {
                            this.setState({ errorId: 3 });
                        }
                    });
                }
            }
        }
    }

    sendCertificat() {
        return "ok"
    }

    renderSwitch() {
        const { formatMessage } = this.props.intl;
        const mailto_href = `mailto:info@webevolutions.ch?subject=Shop Ski CLub Crans-Montana : ${this.state.transactionId} (${this.state.nowDateTime.toISOString()})`;
        switch (this.state.errorId) {
            case 0:
                return (
                    <React.Fragment>
                        <div className="ant-alert-success">
                            <Typography style={{ fontSize: '120%' }}><span dangerouslySetInnerHTML={{ __html: formatMessage({ id: 'paysuccess.settled' }) }} /></Typography>
                            <Typography style={{ fontSize: '120%' }}>{formatMessage({ id: 'paysuccess.transaction' })} {this.state.transactionId}</Typography>
                            <Button style={{ marginTop: '40px' }} onClick={() => { localStorage.clear(); window.location.href = '/' }} className="form-button" type="primary" >{formatMessage({ id: 'paysuccess.close' })}</Button>
                        </div>
                    </React.Fragment>
                )
            case 1:
                return (
                    <React.Fragment>
                        <div className="ant-alert-error">
                            <Typography><span dangerouslySetInnerHTML={{ __html: formatMessage({ id: 'paysuccess.notsettled' }) }} /> </Typography>
                            <Typography style={{ fontSize: '120%' }}>{formatMessage({ id: 'paysuccess.transaction' })} {this.state.transactionId}</Typography>
                            <Button style={{ marginTop: '20px' }} className="form-button" type="primary"><a href={mailto_href}>Contactez-nous</a></Button>
                            {/* <Button style={{ marginTop: '20px' }} onClick={() => { localStorage.clear(); window.location.href = '/' }} className="form-button" type="primary" >{formatMessage({ id: 'paysuccess.close' })}</Button> */}
                        </div>
                        <Button style={{ marginTop: '40px' }} onClick={() => { localStorage.clear(); window.location.href = '/' }} className="form-button" type="primary" >{formatMessage({ id: 'paysuccess.close' })}</Button>
                    </React.Fragment >
                )
            case 2:
                return (
                    <React.Fragment>
                        <div className="ant-alert-error">
                            <Typography style={{ fontSize: '120%' }}><span dangerouslySetInnerHTML={{ __html: formatMessage({ id: 'paysuccess.notsettled' }) }} /> </Typography>
                            <Typography style={{ fontSize: '120%' }}>{formatMessage({ id: 'paysuccess.transaction' })} {this.state.transactionId}</Typography>
                            <Button style={{ marginTop: '20px' }} className="form-button" type="primary"><a href={mailto_href}>Contactez-nous</a></Button>
                            {/* <Button style={{ marginTop: '20px' }} onClick={() => { localStorage.clear(); window.location.href = '/' }} className="form-button" type="primary" >{formatMessage({ id: 'paysuccess.close' })}</Button> */}
                        </div>
                        <Button style={{ marginTop: '40px' }} onClick={() => { localStorage.clear(); window.location.href = '/' }} className="form-button" type="primary" >{formatMessage({ id: 'paysuccess.close' })}</Button>
                    </React.Fragment>
                )
            case 3:
                return (
                    <React.Fragment>
                        <div className="ant-alert-error">
                            <Typography style={{ fontSize: '120%' }}><span dangerouslySetInnerHTML={{ __html: formatMessage({ id: 'paysuccess.notsettled' }) }} /> </Typography>
                            <Typography style={{ fontSize: '120%' }}>{formatMessage({ id: 'paysuccess.transaction' })} {this.state.transactionId}</Typography>
                            <Button style={{ marginTop: '20px' }} className="form-button" type="primary"><a href={mailto_href}>Contactez-nous</a></Button>
                            {/* <Button style={{ marginTop: '20px' }} onClick={() => { localStorage.clear(); window.location.href = '/' }} className="form-button" type="primary" >{formatMessage({ id: 'paysuccess.close' })}</Button> */}
                        </div>
                        <Button style={{ marginTop: '40px' }} onClick={() => { localStorage.clear(); window.location.href = '/' }} className="form-button" type="primary" >{formatMessage({ id: 'paysuccess.close' })}</Button>
                    </React.Fragment>
                );
            default:
                return (
                    <React.Fragment>
                        <Typography style={{ fontSize: '120%', fontWeight: 'bold' }}>{formatMessage({ id: 'paysuccess.loading' })}</Typography>
                        <LoadingOutlined style={{ marginTop: '20px', fontSize: '48px' }} />
                    </React.Fragment>
                )
        }
    }

    render() {
        return (
            <Row>
                <Col span={24} className="success-div" style={{ textAlign: 'center', maxWidth: '1000px', margin: 'auto' }}>
                    {this.renderSwitch()}
                </Col>
            </Row>
        );
    }
}

export default withRouter(injectIntl(Paysuccess));